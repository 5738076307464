/*
Project : Cryptotrades
FileName :  web3.service.ts
Author : LinkWell
File Created : 21/07/2021
CopyRights : LinkWell
Purpose : This is the service which used to handle metamask related functions
*/
import { Injectable } from "@angular/core";
import { ethers } from "ethers";
import { abi } from "../constants/abi";
import { config } from "../constants/config";
import { mulitisend } from "../constants/MultiSend";
@Injectable({
  providedIn: "root",
})
export class Web3Service {
  web3Provider: any;
  address: any;
  signer: any;
  constructor() {}

  createContract = (data: any, gasFee: any, callback: any) => {
    let gas_fee = parseInt(gasFee);
    console.log('gas fee',gas_fee);
    console.log('ethis',ethers.utils.parseUnits(gas_fee.toString(), 'gwei'));
    this.web3Provider
      .send("eth_sendTransaction", [
        {
          from: this.address,
          data: data,
          gasLimit: ethers.utils.hexlify(gas_fee * 100000), // Convert gas limit to hex
          gasPrice: ethers.utils.parseUnits(gas_fee.toString(), 'gwei').toHexString()
        },
      ])
      .then((result: any) => {
        var resulter = {
          status: true,
          result: result,
          message: "create contract hash created successfully",
        };
        callback(resulter);
      })
      .catch((error: any) => {
        console.log("checking1 ", error);
        var result = {
          status: false,
          message: "create contract failed access",
        };
        callback(result);
      });
  };
  

  createContract_test = async (data: any, gasFee: any, callback: any) => {
  
    // Parse the gas fee as an integer
    let gas_fee = parseInt(gasFee);
  
    // Debugging: Log the gas fee
    console.log('Gas fee:', gas_fee);
  
    // Debugging: Log the gas price calculation
    const gasPrice = ethers.utils.parseUnits(gas_fee.toString(), 'gwei').toHexString();
    console.log('Gas price (in hex):', gasPrice);

    // this.address = '0x4EA390fC1F9E1692df737b66c64F84C72474bb19';
    const sampleData = '0x608060405234801561001057600080fd5b506040516020806101238339810180604052810190808051820192919060200180518201929190505050806000819055505061011e8061005f6000396000f3fe608060405260043610603f5760003560e01c80636057361d1460445780636d4ce63c146062575b600080fd5b604a606e565b60408051918252519081900360200190f35b60586078565b005b6000548156fea26469706673582212201b82f0c4f01e2e5e5b69f6e1edc198edb0b27f7e5403c315b2c83b82e2b0b4d664736f6c63430007060033'; // Sample contract bytecode
  
    // Create the transaction parameters
    const transactionParams = {
      from: this.address,
      data: data,
      gasLimit: ethers.utils.hexlify(gas_fee * 100000), // Convert gas limit to hex
      gasPrice: gasPrice // Gas price in hex
    };
  
    // Debugging: Log the transaction parameters
    console.log('Transaction parameters:', transactionParams);
  
    // Send the transaction using the web3 provider
    this.web3Provider
      .send("eth_sendTransaction", [transactionParams])
      .then((result: any) => {
        // Success: Construct a success result object
        var resulter = {
          status: true,
          result: result,
          message: "Create contract hash created successfully",
        };
        // Call the callback with the success result
        callback(resulter);
      })
      .catch((error: any) => {
        // Error: Log the error for debugging
        console.error("Transaction Error:", error);
  
        // Construct an error result object
        var result = {
          status: false,
          message: `Create contract failed: ${error.message || error}`,
        };
  
        // Call the callback with the error result
        callback(result);
      });
  };

  approveContract = (contract_address: any, gasFee: any, callback: any) => {
    let gas_fee = parseInt(gasFee);
    const currentContract = new ethers.Contract(
      contract_address,
      abi,
      this.signer
    );
    console.log(this.address, config.main_address);
    currentContract
      .isApprovedForAll(this.address, config.main_address)
      .then((isApproved: any) => {
        if (isApproved) {
          var resulter = {
            status: true,
            result: isApproved,
            message: "approve success",
          };
          callback(resulter);
        } else {
          currentContract
            .setApprovalForAll(config.main_address, true, {
              gasLimit: gas_fee * 100000, 
              gasPrice: ethers.utils.parseUnits(gas_fee.toString(), 'gwei'), 
            })
            .then((result: any) => {
              var resulter = {
                status: true,
                result: result,
                message: "approve success",
              };
              callback(resulter);
            })
            .catch((error: any) => {
              console.log(error);
              var resulter = {
                status: false,
                message: "approve failed",
              };
              callback(resulter);
            });
        }
      });
  };

  sendMoneyToOwner = (
    addressArray: any,
    priceArray: any,
    priceValue: any,
    gasFee: any,
    callback: any
  ) => {
    let gas_fee = parseInt(gasFee);
    const currentContract = new ethers.Contract(
      config.multi_contract_address,
      mulitisend,
      this.signer
    );
    currentContract
      .sendETH(addressArray, priceArray, {
        gasLimit: gas_fee * 100000,
        gasPrice: ethers.utils.parseUnits(gas_fee.toString(), 'gwei'),
        value: priceValue,
      })
      .then((result: any) => {
        var resulter = {
          status: true,
          result: result,
          message: "money send successful",
        };
        callback(resulter);
      })
      .catch((error: any) => {
        console.log(error);
        var resulter = {
          status: false,
          message: "money failed send",
        };
        callback(resulter);
      });
  };

  mintContract = async (contract_address: any,  gasFee: any, callback: any) => {
    let gas_fee = parseInt(gasFee);
    const currentContract = new ethers.Contract(
      contract_address,
      abi,
      this.signer
    );
    currentContract
      .mint(this.address,{
        gasLimit: gas_fee * 100000, 
        gasPrice: ethers.utils.parseUnits(gas_fee.toString(), 'gwei'), 
      })
      .then((result: any) => {
        var resulter = {
          status: true,
          result: result.hash,
          message: "minted  successfully",
        };
        callback(resulter);
      })
      .catch((error: any) => {
        var resulter = {
          status: false,
          message: "minted failed",
        };
        callback(resulter);
      });
  };

  getTransactionConract = (hash: any, callback: any) => {
    this.web3Provider
      .send("eth_getTransactionReceipt", [hash])
      .then((tresult: any) => {
        console.log("transaction receipt ", tresult);
        if (tresult != null) {
          var resulter = {
            status: true,
            result: tresult.contractAddress,
            message: "mint created successfully",
          };
          callback(resulter);
        } else {
          var result = {
            status: false,
            message: "mint created failed",
          };
          callback(result);
        }
      })
      .catch((error: any) => {
        console.log(error);
        var resulter = {
          status: false,
          message: "minted failed",
        };
        callback(resulter);
      });
  };

  getTransactionToken = (hash: any, callback: any) => {
    this.web3Provider
      .send("eth_getTransactionReceipt", [hash])
      .then((tresult: any) => {
        console.log("transaction receipt ", tresult);
        if (tresult != null) {
          var resulter = {
            status: true,
            result: tresult.logs[0].topics[3],
            message: "mint created successfully",
          };
          callback(resulter);
        } else {
          var result = {
            status: false,
            message: "mint created failed",
          };
          callback(result);
        }
      })
      .catch((error: any) => {
        console.log(error);
        var resulter = {
          status: false,
          message: "minted failed",
        };
        callback(resulter);
      });
  };
}
