
<div class="collectionItem">
    <div class="collectionItemImg" (click)="viewAction()">
        <img [src]="item.thumb" class="collectionItemImgBanner" *ngIf="item.is_imported && item.thumb !== undefined"/>
        <img [src]="mediaBase + '/images/nia.jpg'" class="collectionItemImgBanner" *ngIf="item.is_imported && item.thumb == undefined"/>
        <img [src]="mediaBase + '/images/item/thumb/' + item.thumb" class="collectionItemImgBanner" *ngIf="!item.is_imported"/>
    </div>
   <div class="collectionItemContent">
        <div class="collectionItemTitle">
            {{item.name}}
        </div>
        <div class="collectionItemCount">
            Price {{item.price}} MATIC
        </div>
   </div> 
</div>
