/*
Project : Cryptotrades
FileName :  config.ts
Author : LinkWell
File Created : 21/07/2021
CopyRights : LinkWell
Purpose : This is the file which contain all global constants used in the application
*/
export const config = {
    site_name: "Cryptotrades",
     media_path: "https://ikoapi.metadoersworld.com",
    //  media_path: "http://localhost:3001",
     base_url: "https://ikoapi.metadoersworld.com",
   // media_path: "http://localhost:5000",
    // base_url: "http://localhost:3001",
    google_client_id:'463197681074-rt0537fuvclatgjes4mct01gf19ja8ar.apps.googleusercontent.com',
    facebook_client_id:'370223044026040',
    blockchain:"Mainnet",
    blockchain_url:"https://rpc-mainnet.maticvigil.com/",
    // blockchain_url:"https://polygon-mainnet.infura.io/v3/93c8935a1c294d0ebdc109bc3b42b753",
    onboard: {
        key: 'b9c4f15a-de2e-440b-97a0-7925abab9353',
        network: 137//80001
    },
    main_address: "0xE35F218EE8A3f3814C5162c164c225bDD77D4983",
    multi_contract_address: "0x84832b082fF6f40Aebe4459766F1AF1570B12BC1"
}